import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { doLogout } from '../modules/auth/stores/slice';

const LogoutPage = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    auth?.authenticated && dispatch(doLogout({}));
    window.location.reload();
  }, []);

  return auth.authenticated ? null : <Navigate to="/login" />;
};

export default LogoutPage;
