import request from '../../utils/request';
import requestFormData from '../../utils/requestFormData';

export function getCoaches(params) {
  return request('/coaches', {
    method: 'GET',
    params: params,
  });
}

export function getCoach(id) {
  return request('/coaches/' + id, {
    method: 'GET',
  });
}

export function getCoachesByUsers() {
  return request('/coaches/me', {
    method: 'GET',
  });
}

export function createCoach(data) {
  return requestFormData('/coaches', {
    method: 'POST',
    data,
  });
}

export function updateCoach(data) {
  const id = data.get('id');
  data.delete('id');
  return requestFormData(`/coaches/${id}`, {
    method: 'PATCH',
    data,
  });
}
