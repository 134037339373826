import { combineReducers } from '@reduxjs/toolkit';

import commonReducer from './slice';
import authReducer from '../modules/auth/stores/slice';
import coachPropertyReducer from '../modules/coachProperty/stores/slice';
import coachingInquiryReducer from '../modules/coachingInquiry/stores/slice';
import coachingInquiryUserReducer from '../modules/coachingInquiryUser/stores/slice';
import userLanguageReducer from '../modules/userLanguage/stores/slice';
import CoachReducer from '../modules/coaches/stores/slice';
import UserReducer from '../modules/users/stores/slice';
import coachPropertiesCoachReducer from '../modules/coachPropertyCoach/stores/slice';

const appReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  coachProperty: coachPropertyReducer,
  coachPropertiesCoach: coachPropertiesCoachReducer,
  coachingInquiry: coachingInquiryReducer,
  userLanguage: userLanguageReducer,
  coachingInquiryUser: coachingInquiryUserReducer,
  coach: CoachReducer,
  user: UserReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
