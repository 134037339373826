export const APP_NAME = 'Wand';
export const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME || '_wand_session';
export const COACH_PROPERTY_TYPE = {
  TEXTAREA: 'TEXTAREA',
  INPUT: 'INPUT',
  SELECT: 'SELECT',
  RADIO: 'RADIO',
  SELECT_MULTIPLE: 'SELECT_MULTIPLE',
};

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
};

export const PAGE_SIZE = 15;

export const PAGE_SIZE_OPTIONS = [PAGE_SIZE, 50, 100];

export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A';

export const SELECT_ALL_OPTION = {
  key: 'ALL',
  value: 'ALL',
};

export const FIELD_BEFORE_GOALS = [
  'age_range',
  'careeer_stage',
  'other_info',
  'gender',
  'location_preference',
  'time_zone',
  'meeting_prefs',
];
