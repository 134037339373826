import request from '../../utils/request';

export function getCoachProperties() {
  return request('/coach-properties', {
    method: 'Get',
  });
}

export function createCoachProperties(data) {
  return request('/coach-properties', {
    method: 'POST',
    data,
  });
}
