import { Button, Form, Input, Switch, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import TagInput from '../TagInput';
import './Coach.scss';
import { UploadOutlined } from '@ant-design/icons';

const CoachForm = ({ handleSubmit, action, coach, handleCancel }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [tags, setTags] = useState([]);

  const [fileList, setFileList] = useState([]);

  const handleChangeTags = (values) => {
    setTags(values);
  };

  const onFinishForm = (values) => {
    handleSubmit && handleSubmit({ ...values, tags });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return fileList;
  };

  const handleChangeLogo = (info) => {
    let newFileList = [...info.fileList];
    const file = newFileList.slice(-1)[0];
    const isLt2M = (file?.size || 0) / 1024 / 1024 <= 2;
    if (!isLt2M) {
      messageApi.error({ message: 'Image must smaller than 2MB!' });
    } else {
      newFileList = newFileList.slice(-1);
      setFileList(newFileList);
    }
  };

  useEffect(() => {
    if (action === 'new') {
      form.resetFields();
      setFileList([]);
      setTags([]);
    } else if (coach) {
      form.setFieldsValue(coach);
      if (coach.avatar) {
        setFileList([
          {
            uid: coach.id + 'avatar',
            status: 'done',
            url: coach.url,
            name: coach.avatar,
          },
        ]);
      }
      setTags(coach?.tags || []);
    }
  }, [action, coach]);

  return (
    <div className="CoachForm">
      {contextHolder}
      <div className="FormHeader"></div>
      <div className="FormContent">
        <Form
          form={form}
          scrollToFirstError
          layout="vertical"
          requiredMark={false}
          colon={false}
          onFinish={onFinishForm}
        >
          <Form.Item label="Full Name" name="fullName" rules={[{ required: true, message: 'Required' }]}>
            <Input size="large" placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: 'email', message: 'Invalid Email' },
              { required: true, message: 'Required' },
            ]}
          >
            <Input size="large" placeholder="Email" />
          </Form.Item>
          <Form.Item label="Role" name="role" rules={[{ required: true, message: 'Required' }]}>
            <Input size="large" placeholder="Role" />
          </Form.Item>
          <Form.Item label="Cost per Hour" name="costPerHour" rules={[{ required: true, message: 'Required' }]}>
            <Input size="large" type="number" placeholder="Cost per Hour" suffix="SGD" />
          </Form.Item>
          <Form.Item label="Tags" name="tags" rules={[{ required: true, message: 'Required' }]}>
            <TagInput tags={tags} onChange={handleChangeTags} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea size="large" placeholder="Description" />
          </Form.Item>
          <Form.Item label="Active" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            label="Avatar"
            name="avatar"
            validateStatus={!fileList || fileList.length == 0 ? 'error' : undefined}
            help={!fileList || fileList.length == 0 ? 'Required' : undefined}
            getValueFromEvent={normFile}
          >
            <Upload.Dragger
              multiple={false}
              maxCount={1}
              accept=".png, .jpg, .jpeg"
              showUploadList={{
                showPreviewIcon: false,
                showDownloadIcon: false,
                showRemoveIcon: true,
              }}
              customRequest={async ({ onSuccess, onError }) => {
                try {
                  onSuccess('ok');
                } catch (e) {
                  console.log(e);
                  onError();
                }
              }}
              onRemove={() => setFileList([])}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChangeLogo}
            >
              <p className="upload-text">Drag and drop to upload or</p>
              <p className="upload-drag-icon">
                Browse
                <UploadOutlined />
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </div>
      <div className="FormFooter">
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="primary" block size="large" onClick={() => form.submit()}>
          {action === 'new' ? 'Create' : 'Update'}
        </Button>
      </div>
    </div>
  );
};

export default CoachForm;
