import React from 'react';
import MainHeader from './Header';

const MainLayout = ({ children, isHeaderFooter }) => {
  return (
    <main id="MainLayout" className={isHeaderFooter ? 'is-admin Layouts' : 'Layouts'}>
      {isHeaderFooter ? <MainHeader /> : null}
      {children}
    </main>
  );
};

export default MainLayout;
