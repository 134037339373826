import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { doGetUser } from '../modules/users/stores/thunk';
import { Spin } from 'antd';
import './User.scss';
import BreadCrumb from '../modules/breadcrumbs';
import { doGetCoachProperties } from '../modules/coachProperty/stores/thunk';

const UserPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const breadCrumbs = [{ text: 'Users', url: '/users' }, { text: `User ${id}` }];

  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetchingUser = useSelector((state) => state.user.isFetching);

  const coachProperties = useSelector((state) => state.coachProperty.items);
  const isFetchingCoachProperties = useSelector((state) => state.coachProperty.isFetching);

  const isFetching = isFetchingUser || isFetchingCoachProperties;

  console.log(coachProperties);
  useEffect(() => {
    dispatch(doGetUser({ id }));
    dispatch(doGetCoachProperties());
  }, []);

  console.log({ currentUser });

  if (!currentUser) return <Spin style={{ paddingTop: 100, width: '100%' }} />;

  const { coachesUsers } = currentUser;

  const coachPropertiesUsers = coachesUsers?.[0]?.coachPropertyCoachUsers;

  const coach = coachesUsers[0]?.coach;

  return (
    <Spin spinning={isFetching}>
      <div className="Content UserPage">
        <BreadCrumb breadCrumbs={breadCrumbs} />
        <div className="wrapper">
          <div className="header">
            <h4 className="title">Detail</h4>
          </div>
          <div className="body">
            <div className="body-content">
              <label>Given Name</label>
              <p>{currentUser.givenName}</p>
            </div>
            <div className="body-content">
              <label>Family Name</label>
              <p>{currentUser.familyName}</p>
            </div>
            <div className="body-content">
              <label>Email</label>
              <p>{currentUser.email}</p>
            </div>
            <div className="body-content">
              <label>Access Code</label>
              <p>{currentUser.accessCode || '-'}</p>
            </div>
          </div>
        </div>
        {coachPropertiesUsers?.length > 0 && (
          <div className="wrapper">
            <div className="header">
              <h4 className="title">Current Coach Properties</h4>
            </div>
            <div className="body">
              {coachPropertiesUsers.map((coachPropertiesUser) => {
                const coachProperty = coachProperties?.find((item) => item.field === coachPropertiesUser.field);
                return (
                  <div key={`${coachPropertiesUser.id}_registerPropertiesUser`} className="body-content">
                    <label>
                      {coachProperty?.labelEmail ||
                        coachPropertiesUser?.label ||
                        coachProperty?.label ||
                        coachPropertiesUser?.field}
                    </label>
                    <p>{coachPropertiesUser.inputValue}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {coachesUsers?.length > 0 && (
          <div className="wrapper">
            <div className="header">
              <h4 className="title">Current Coach</h4>
            </div>
            <div className="body">
              <div className="body-content">
                <label>Coach Name</label>
                <p>{coach.fullName}</p>
              </div>
              <div className="body-content">
                <label>Coach Email</label>
                <p>{coach.email}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default UserPage;
