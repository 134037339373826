import request from '../../utils/request';

export function signIn(params) {
  return request('/auth/login', {
    method: 'POST',
    data: params,
  });
}

export function signUp(params) {
  return request('/auth/register', {
    method: 'POST',
    data: params,
  });
}

export function getProfile() {
  return request('/auth/profile', {
    method: 'GET',
  });
}
