import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError, setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doSignIn = createAsyncThunk('auth/doSignIn', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.signIn(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doSignUp = createAsyncThunk('auth/doSignUp', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.signUp(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doGetProfile = createAsyncThunk('auth/doGetProfile', async (params, thunkAPI) => {
  try {
    const { response, error } = await api.getProfile(params);
    if (error) {
      thunkAPI.dispatch(setError(error));
      return thunkAPI.rejectWithValue(error);
    } else {
      return response;
    }
    // return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    console.log('e', e);
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});
