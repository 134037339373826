import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { TOKEN_NAME } from '../../../constants';
import { doGetProfile, doSignIn, doSignUp } from './thunk';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isFetching: false,
    isError: false,
    authenticated: !!Cookies.get(TOKEN_NAME),
  },
  reducers: {
    doLogout: (state) => {
      Cookies.remove(TOKEN_NAME);
      state.authenticated = false;
    },
    setInitError: (state) => {
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doSignIn.fulfilled, (state, action) => {
      const token = action.payload?.access_token;
      if (token) {
        Cookies.set(TOKEN_NAME, token);
        state.authenticated = true;
      }
    });
    builder.addCase(doSignUp.fulfilled, (state, action) => {
      const token = action.payload?.access_token;
      if (token) {
        Cookies.set(TOKEN_NAME, token);
        state.authenticated = true;
      }
    });
    builder.addCase(doSignUp.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doSignUp.rejected, (state) => {
      state.isFetching = false;
      state.isError = true;
    });
    builder.addCase(doGetProfile.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetProfile.fulfilled, (state, action) => {
      state.isFetching = false;
      state.user = action.payload;
    });
    builder.addCase(doGetProfile.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

const { actions, reducer: authReducer } = authSlice;
export const { doLogout, setInitError } = actions;
export default authReducer;
