import React, { useState } from 'react';
import LoginPage from '../components/Login';
import RegisterPage from '../components/Register';
import { AuthContainer } from '../modules/auth/containers';

export const AuthPages = {
  login: 'login',
  register: 'register',
};

const AuthPage = () => {
  const [currentPage, setCurrentPage] = useState(AuthPages.login);

  return (
    <>
      {currentPage === AuthPages.login ? (
        <LoginPage setCurrentPage={setCurrentPage} />
      ) : (
        <RegisterPage currentPage={currentPage} setCurrentPage={setCurrentPage} />
      )}
    </>
  );
};

export default AuthContainer(AuthPage);
