import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';

/**
 * BreadCrumb component
 *
 * @param {Object} props - The component props
 * @param {Array} props.breadCrumbs - The array of breadcrumb objects
 * @param {string} props.breadCrumbs[].url - The URL of the breadcrumb
 * @param {string} props.breadCrumbs[].text - The text of the breadcrumb
 * @return {JSX.Element} The rendered BreadCrumb component
 */
const BreadCrumb = ({ breadCrumbs }) => {
  // Map the breadCrumbs array to an array of items
  const items = breadCrumbs.map((breadCrumb) => {
    // If the breadCrumb has a URL, render it as a Link component
    return {
      title: breadCrumb.url ? <Link to={breadCrumb.url}>{breadCrumb.text}</Link> : breadCrumb.text,
    };
  });

  // Render the Breadcrumb component with the mapped items
  return (
    <Breadcrumb
      className="BreadCrumb"
      // The items to display in the breadcrumb
      items={items}
      // The component to use as a separator between breadcrumb items
      separator={<RightOutlined />}
    />
  );
};
export default BreadCrumb;
