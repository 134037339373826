import request from '../../utils/request';

export function getUsers(params) {
  return request('/users', {
    method: 'GET',
    params: params,
  });
}

export function getUser(params) {
  return request(`/users/${params.id}`, {
    method: 'GET',
  });
}
