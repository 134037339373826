import { createSlice } from '@reduxjs/toolkit';

import { doCreateCoachPropertiesCoach, doGetCoachPropertiesCoach, doGetCoachPropertiesCoachAll } from './thunk';

const coachPropertiesCoachSlice = createSlice({
  name: 'coachPropertiesCoach',
  initialState: {
    isFetching: false,
    items: [],
    allItems: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetCoachPropertiesCoach.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetCoachPropertiesCoach.fulfilled, (state, action) => {
      state.isFetching = false;
      state.items = action.payload;
    });
    builder.addCase(doGetCoachPropertiesCoach.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doGetCoachPropertiesCoachAll.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetCoachPropertiesCoachAll.fulfilled, (state, action) => {
      state.isFetching = false;
      state.allItems = action.payload;
    });
    builder.addCase(doGetCoachPropertiesCoachAll.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doCreateCoachPropertiesCoach.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doCreateCoachPropertiesCoach.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doCreateCoachPropertiesCoach.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

const { reducer: coachPropertiesCoachReducer } = coachPropertiesCoachSlice;

export default coachPropertiesCoachReducer;
