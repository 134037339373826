export function convertModelToFormData(val, formData = new FormData(), namespace = '') {
  if (typeof val !== 'undefined' && val !== null) {
    if (val instanceof Date) {
      formData.append(namespace, val.toISOString());
    } else if (val instanceof Array) {
      formData.append(namespace, JSON.stringify(val));
    } else if (typeof val === 'object' && !(val instanceof File)) {
      for (const propertyName in val) {
        // eslint-disable-next-line no-prototype-builtins
        if (val.hasOwnProperty(propertyName)) {
          convertModelToFormData(
            val[propertyName],
            formData,
            namespace ? namespace + '[' + propertyName + ']' : propertyName,
          );
        }
      }
    } else if (val instanceof File) {
      formData.append(namespace, val);
    } else {
      formData.append(namespace, val ? val.toString() : null);
    }
  }
  return formData;
}
