import React from 'react';
import './CoachingMember.scss';
import { Button } from 'antd';

const CoachingMemberPopup = ({ show, onClose, member }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="CoachingMemberPopup">
      <div className="popup-content">
        <h2>Congrats!</h2>
        <p className="top">We have sent you and {member.fullName} an introduction email.</p>
        <div className="popup-image">
          <img src={member.url} alt="Profile" />
        </div>
        <p className="bottom">Happy learning!</p>
        <Button type="primary" htmlType="submit" block size="large" onClick={onClose}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default CoachingMemberPopup;
