import React from 'react';

const LayoutAuth = ({ children }) => {
  return (
    <main className="Layouts" id="LayoutAuth">
      {children}
    </main>
  );
};

export default LayoutAuth;
