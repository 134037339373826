import { Button, Form, Input, message, Radio, Select, Space, Spin } from 'antd';
import React, { useEffect } from 'react';
import sortBy from 'lodash/sortBy';
import './Coach.scss';
import { COACH_PROPERTY_TYPE } from '../../constants';
import useSelectAllOption from '../../Hooks/useSelectAllOption';
import { useDispatch, useSelector } from 'react-redux';
import { doGetCoachPropertiesCoach } from '../../modules/coachPropertyCoach/stores/thunk';

const coachProperties = [
  {
    id: 42,
    type: 'SELECT',
    label:
      'What is the maximum number  of coachees you are willing to take for beta testing (25 July to 30 Oct 2024)?  Each coachee receives up to 3 sessions.',
    field: 'maximum_number',
    priority: 0,
    choiceValues: [
      {
        id: 21,
        label: '1',
        priority: 1,
        coachPropertyId: 43,
      },
      {
        id: 22,
        label: '2',
        priority: 2,
        coachPropertyId: 43,
      },
      {
        id: 23,
        label: '3',
        priority: 3,
        coachPropertyId: 43,
      },
      {
        id: 24,
        label: '4',
        priority: 4,
        coachPropertyId: 43,
      },
      {
        id: 20,
        label: '5',
        priority: 5,
        coachPropertyId: 43,
      },
    ],
  },
  {
    id: 43,
    type: 'SELECT_MULTIPLE',
    label: 'What is the age range of coachees you are confident and interested to coach?',
    field: 'age_range',
    priority: 1,
    choiceValues: [
      {
        id: 216,
        label: '15-19',
        priority: 1,
        coachPropertyId: 43,
      },
      {
        id: 217,
        label: ' 20-25',
        priority: 3,
        coachPropertyId: 43,
      },
      {
        id: 218,
        label: '26-30',
        priority: 5,
        coachPropertyId: 43,
      },
      {
        id: 219,
        label: '31-40',
        priority: 7,
        coachPropertyId: 43,
      },
      {
        id: 220,
        label: '41-50',
        priority: 9,
        coachPropertyId: 43,
      },
      {
        id: 221,
        label: '51-60',
        priority: 11,
        coachPropertyId: 43,
      },
      {
        id: 222,
        label: 'above 60',
        priority: 13,
        coachPropertyId: 43,
      },
    ],
  },
  {
    id: 44,
    type: 'SELECT_MULTIPLE',
    label: 'Which of the following career stage(s) are you confident and keen to coach? Tick all that you are.',
    field: 'careeer_stage',
    priority: 3,
    choiceValues: [
      {
        id: 223,
        label: 'Fresh graduate - just started their professional life in the last 12 months',
        priority: 1,
        coachPropertyId: 44,
      },
      {
        id: 224,
        label: 'Young professional -  have worked for 1 to 5 years',
        priority: 3,
        coachPropertyId: 44,
      },
      {
        id: 225,
        label: 'Individual contributor - with at least 5 years of experience',
        priority: 5,
        coachPropertyId: 44,
      },
      {
        id: 226,
        label: 'First time leader - have at least one person reporting to them',
        priority: 7,
        coachPropertyId: 44,
      },
      {
        id: 227,
        label: 'Team leader - have been leading others for at least 3 years',
        priority: 9,
        coachPropertyId: 44,
      },
      {
        id: 228,
        label: 'Career transition - moving to another type of role',
        priority: 11,
        coachPropertyId: 44,
      },
    ],
  },
  {
    id: 45,
    type: 'TEXTAREA',
    label:
      'What else would you like to share about your coaching skills and interests?  Please add key words like industries, functions, transitions that you have experience in.  You may also put in personal roles like being a parent. These will help with the algorithm matching.',
    field: 'other_info',
    priority: 5,
    choiceValues: [],
  },
  {
    id: 46,
    type: 'RADIO',
    label: 'Your gender',
    field: 'gender',
    priority: 7,
    choiceValues: [
      {
        id: 229,
        label: 'Female',
        priority: 1,
        coachPropertyId: 46,
      },
      {
        id: 230,
        label: 'Male',
        priority: 3,
        coachPropertyId: 46,
      },
    ],
  },
  {
    id: 47,
    type: 'RADIO',
    label: 'Do you prefer your coachees to be',
    field: 'location_preference',
    priority: 9,
    choiceValues: [
      {
        id: 231,
        label: 'Based in the same country as I am',
        priority: 1,
        coachPropertyId: 47,
      },
      {
        id: 232,
        label: 'Based anywhere in the world',
        priority: 3,
        coachPropertyId: 47,
      },
    ],
  },
  {
    id: 48,
    type: 'INPUT',
    label: 'Which country are you in so we know your time zone?',
    field: 'time_zone',
    priority: 11,
    choiceValues: [],
  },
  {
    id: 49,
    type: 'SELECT',
    label: 'How do you prefer your coaching to be done?',
    field: 'meeting_prefs',
    priority: 13,
    choiceValues: [
      {
        id: 233,
        label: 'Face to face',
        priority: 1,
        coachPropertyId: 49,
      },
      {
        id: 234,
        label: 'Zoom or another virtual platform',
        priority: 3,
        coachPropertyId: 49,
      },
      {
        id: 235,
        label: 'No preference',
        priority: 5,
        coachPropertyId: 49,
      },
    ],
  },
  {
    id: 50,
    type: 'TEXTAREA',
    label:
      'How would you describe yourself as a coach and/or your coaching method in 50 words or less? (we may use/adapt your reflection as a way to introduce you in the flyer and website)',
    field: 'elaborate_on_goals',
    priority: 15,
    choiceValues: [],
  },
  {
    id: 54,
    type: 'SELECT_MULTIPLE',
    field: 'goals',
    label:
      'Here is a list of goals we are asking coachees to choose from. Please pick ALL that you are confident and interested (high will and high skill) to coach on.',
    priority: 15.5,
    choiceValues: [],
    choiceValues: [
      {
        id: 1,
        label: 'Achieve results',
        priority: 1,
        coachPropertyId: 51,
      },
      {
        id: 2,
        label: 'Build resilience',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 3,
        label: 'Career direction',
        priority: 5,
        coachPropertyId: 51,
      },
      {
        id: 4,
        label: 'Confidence',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 5,
        label: 'Communication',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 6,
        label: 'Executive presence',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 7,
        label: 'Family matters',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 8,
        label: 'Healthy lifestyle',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 9,
        label: 'Imposter syndrome',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 10,
        label: 'Influence others',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 11,
        label: 'Life purpose',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 12,
        label: 'Manage change',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 13,
        label: 'Manage stakeholders',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 14,
        label: 'Mental wellbeing',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 15,
        label: 'Peer pressure',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 16,
        label: 'Problem solving',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 17,
        label: 'Resolve conflict',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 2,
        label: 'Work-life satisfaction',
        priority: 3,
        coachPropertyId: 51,
      },
    ],
  },
  {
    id: 60,
    type: 'SELECT_MULTIPLE',
    label: 'Which of the following languages are you proficient to coach in?',
    field: 'language',
    priority: 16,
    choiceValues: [
      {
        id: 601,
        label: 'English',
        priority: 1,
        coachPropertyId: 51,
      },
      {
        id: 602,
        label: 'Mandarin',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 603,
        label: 'French',
        priority: 5,
        coachPropertyId: 51,
      },
      {
        id: 604,
        label: 'Spanish',
        priority: 5,
        coachPropertyId: 51,
      },
      {
        id: 605,
        label: 'Turkish',
        priority: 5,
        coachPropertyId: 51,
      },
    ],
  },
  {
    id: 62,
    type: 'INPUT',
    label: 'Any other language(s)?',
    field: 'other_language',
    priority: 17,
  },
  {
    id: 51,
    type: 'SELECT',
    label: 'Best way to communicate with you is via',
    field: 'communication_prefs',
    priority: 18,
    choiceValues: [
      {
        id: 236,
        label: 'Email',
        priority: 1,
        coachPropertyId: 51,
      },
      {
        id: 237,
        label: 'Whatsapp',
        priority: 3,
        coachPropertyId: 51,
      },
      {
        id: 238,
        label: 'Both email and Whataspp',
        priority: 5,
        coachPropertyId: 51,
      },
    ],
  },
  {
    id: 52,
    type: 'INPUT',
    label: 'Provide the details for way to communicate with you. Eg email address/ (area code) mobile number',
    field: 'communication_details',
    priority: 19,
    choiceValues: [],
  },
  {
    id: 53,
    type: 'TEXTAREA',
    field: 'feedback',
    label:
      'As this is a beta test, we will be asking you for feedback on how your experience has been. Do you allow us to interview you after your coaching session(s)?',
    priority: 21,
    choiceValues: [],
  },
];

const CoachQuestionForm = ({ handleSubmit, action, handleCancel, coach }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [messageApi, contextHolder] = message.useMessage();

  const isFetching = useSelector((state) => state.coachPropertiesCoach.isFetching);
  const coachPropertiesCoaches = useSelector((state) => state.coachPropertiesCoach.items);

  useEffect(() => {
    if (coach?.id) {
      dispatch(doGetCoachPropertiesCoach({ id: coach.id }));
    }
  }, [coach]);

  useEffect(() => {
    if (coachPropertiesCoaches.length > 0) {
      form.setFieldsValue(
        coachPropertiesCoaches.reduce((acc, curr) => {
          if (['goals', 'careeer_stage', 'language', 'age_range'].includes(curr.field)) {
            acc[curr.label] = curr.inputValue?.split(',');
            return acc;
          }
          acc[curr.label] = curr.inputValue;
          return acc;
        }, {}),
      );
    } else {
      form.resetFields();
    }
  }, [coachPropertiesCoaches]);

  const onFinishForm = (values) => {
    const newData = [];
    Object.keys(values).forEach((key) => {
      const coachProperty = coachProperties?.find((cp) => cp.label === key);
      newData.push({
        label: key,
        field: coachProperty?.field,
        priority: coachProperty?.priority,
        inputValue: Array.isArray(values[key]) ? values[key].join(',') : values[key],
      });
    });
    handleSubmit &&
      handleSubmit({
        coachPropertiesCoaches: sortBy(newData, 'priority'),
        coachId: coach.id,
      });
  };

  return (
    <Spin spinning={isFetching}>
      <div className="CoachQuestionForm">
        {contextHolder}
        <div className="FormHeader"></div>
        <div className="FormContent">
          <Form
            form={form}
            scrollToFirstError
            layout="vertical"
            requiredMark={false}
            colon={false}
            onFinish={onFinishForm}
          >
            {coachProperties?.length > 0 &&
              coachProperties.map((rg) => {
                let content = <></>;
                switch (rg.type) {
                  case COACH_PROPERTY_TYPE.INPUT:
                    content = <Input size="large" placeholder={rg.name} />;
                    break;
                  case COACH_PROPERTY_TYPE.SELECT:
                    content = (
                      <Select
                        size="large"
                        placeholder={rg.name}
                        options={rg.choiceValues.map((cv) => ({
                          label: cv.label,
                          value: cv.label,
                        }))}
                      />
                    );
                    break;
                  case COACH_PROPERTY_TYPE.RADIO:
                    content = (
                      <Radio.Group name="radiogroup" direction="vertical">
                        <Space direction="vertical">
                          {rg.choiceValues.map((c) => (
                            <Radio key={c.label} value={c.label}>
                              {c.label}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    );
                    break;
                  case COACH_PROPERTY_TYPE.TEXTAREA:
                    content = <Input.TextArea size="large" placeholder={rg.label} rows={4} />;
                    break;
                }
                if (rg.type === COACH_PROPERTY_TYPE.SELECT_MULTIPLE) {
                  const options = rg.choiceValues.map((cv) => ({
                    label: cv.label,
                    value: cv.label,
                  }));
                  const [getValueFromEvent, optionsWithAllOption] = useSelectAllOption(options);
                  return (
                    <Form.Item
                      label={rg.label}
                      getValueFromEvent={getValueFromEvent}
                      name={rg.label}
                      key={`${rg.id} ${rg.priority} coach property`}
                      rules={[{ required: true, message: 'Required' }]}
                    >
                      <Select size="large" mode="tags" placeholder={rg.name} options={optionsWithAllOption} />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item
                    label={rg.label}
                    name={rg.label}
                    key={`${rg.id} ${rg.priority} coach property`}
                    rules={[{ required: true, message: 'Required' }]}
                  >
                    {content}
                  </Form.Item>
                );
              })}
          </Form>
        </div>
        <div className="FormFooter">
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" block size="large" onClick={() => form.submit()}>
            {action === 'new' ? 'Create' : 'Update'}
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default CoachQuestionForm;
