import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doGetCoachingInquiries = createAsyncThunk(
  'CoachingInquiries/doGetCoachingInquiries',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.getCoachingInquiries();
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  },
);
