import React, { useRef, useState } from 'react';
import { Input, Tag } from 'antd';

const TagInput = ({ tags = [], onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  // inputRef.current?.focus();

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    onChange(newTags);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      const newTags = [...tags, inputValue];
      onChange(newTags);
    }
    setInputValue('');
  };

  return (
    <div className="tags-input">
      {tags.map((tag) => {
        return (
          <Tag className="edit-tag" key={tag} closable={true} onClose={() => handleClose(tag)}>
            {tag}
          </Tag>
        );
      })}
      <Input
        ref={inputRef}
        type="text"
        size="small"
        className="tag-input"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
        placeholder="Type and press enter"
      />
    </div>
  );
};

export default TagInput;
