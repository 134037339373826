import { createSlice } from '@reduxjs/toolkit';

import { doGetCoachingInquiries } from './thunk';

const authSlice = createSlice({
  name: 'coachingInquiry',
  initialState: {
    isFetching: false,
    items: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetCoachingInquiries.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetCoachingInquiries.fulfilled, (state, action) => {
      state.isFetching = false;
      state.items = action.payload;
    });
    builder.addCase(doGetCoachingInquiries.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

const { actions, reducer: authReducer } = authSlice;
export const { doLogout } = actions;
export default authReducer;
