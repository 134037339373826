import React, { useEffect } from 'react';
import { message as messageAnt } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../redux/slice';

const AppMessage = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = messageAnt.useMessage();

  const message = useSelector((state) => state.common.message);
  useEffect(() => {
    if (message) {
      messageApi.open({
        type: 'success',
        style: {
          marginTop: '68px',
        },
        content: message,
        onClose: () => dispatch(setMessage(null)),
      });
    }
  }, [message, setMessage]);

  return <>{contextHolder}</>;
};

export default AppMessage;
