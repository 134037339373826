import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doGetCoachProperties = createAsyncThunk(
  'CoachProperties/doGetCoachProperties',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.getCoachProperties();
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  },
);

export const doCreateCoachProperties = createAsyncThunk(
  'CoachProperties/doCreateCoachProperties',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.createCoachProperties(params);
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  },
);
