import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import './CoachingInquiry.scss';
import { Button, Form, Input, Radio, Select, Space, Spin } from 'antd';
import CoachingMemberPopup from '../components/Popup/CoachingMember';
import { useDispatch, useSelector } from 'react-redux';
import { doGetCoachingInquiries } from '../modules/coachingInquiry/stores/thunk';
import { setError } from '../redux/slice';
import { doGetUserLanguages } from '../modules/userLanguage/stores/thunk';
import { doCreateCoachingInquiriesUsers } from '../modules/coachingInquiryUser/stores/thunk';
import { doGetCoachesByUser } from '../modules/coaches/stores/thunk';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import { doGetCoachProperties } from '../modules/coachProperty/stores/thunk';
import { COACH_PROPERTY_TYPE, FIELD_BEFORE_GOALS } from '../constants';
import { Link } from 'react-router-dom';
import { doGetCoachPropertiesCoachAll } from '../modules/coachPropertyCoach/stores/thunk';
import { uniq } from 'lodash';

const initStateCoachingPopup = {
  member: undefined,
  show: false,
};

const CoachingInquiryPage = () => {
  const dispatch = useDispatch();
  const { items: coachingInquiries, isFetching: isFetchingCoaching } = useSelector((state) => state.coachingInquiry);

  const { allItems: coachPropertiesCoaches, isFetching: isFetchingCoachPropertiesCoaches } = useSelector(
    (state) => state.coachPropertiesCoach,
  );
  const coachProperties = useSelector((state) => state.coachProperty.items);
  const isFetchingCoachProperties = useSelector((state) => state.coachProperty.isFetching);

  const currentCoach = useSelector((state) => state.coach.currentCoach);
  const isFetchingCoach = useSelector((state) => state.coach.isFetching);

  const { selectedCoach, isFetching: isFetchingCoachingInquiryUser } = useSelector(
    (state) => state.coachingInquiryUser,
  );

  const isFetching =
    isFetchingCoaching ||
    isFetchingCoach ||
    isFetchingCoachingInquiryUser ||
    isFetchingCoachProperties ||
    isFetchingCoachPropertiesCoaches;

  const [selectedItems, setSelectedItems] = useState([]);
  const [coachPropertiesUsersBefore, setCoachPropertiesUsersBefore] = useState([]);
  const [coach, setCoach] = useState();
  const [currentStep, setCurrentStep] = useState(-1);
  const [stateCoachingPopup, setStateCoachingPopup] = useState(initStateCoachingPopup);

  const [formBefore] = Form.useForm();
  const [formAfter] = Form.useForm();

  useEffect(() => {
    dispatch(doGetCoachingInquiries());
    dispatch(doGetUserLanguages());
    dispatch(doGetCoachesByUser());
    dispatch(doGetCoachProperties());
    dispatch(doGetCoachPropertiesCoachAll());
  }, []);

  useEffect(() => {
    if (!isEmpty(currentCoach)) setCoach(currentCoach);
  }, [currentCoach]);

  useEffect(() => {
    if (!isEmpty(selectedCoach)) {
      setCoach(selectedCoach);
      setCurrentStep(2);
      selectedMember(selectedCoach);
    }
  }, [selectedCoach]);

  const onClosePopup = () => {
    setStateCoachingPopup(initStateCoachingPopup);
  };

  console.log(uniq(coachPropertiesCoaches.map((cp) => cp.coachId)));

  const continueStep = async () => {
    if (currentStep === 1) {
      formAfter.submit();
    } else if (currentStep === -1) {
      formBefore.submit();
    } else if (currentStep === 0) {
      if (selectedItems.length === 0) {
        dispatch(setError({ message: 'Please select at least one item' }));
      } else if (selectedItems.length === 3) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  useEffect(() => {
    if (coach) {
      setCurrentStep(2);
    }
  }, [coach]);

  console.log({ coachProperties });

  const handleSubmitNonGoals = async (values, type = 'After') => {
    const newCoachPropertiesUsers = [];
    Object.keys(values).forEach((key) => {
      const coachProperty = coachProperties?.find((cp) => cp.label === key);
      newCoachPropertiesUsers.push({
        label: key,
        field: coachProperty?.field,
        inputValue: values[key],
      });
    });
    if (type === 'After') {
      const groupCoachPropertiesCoaches = groupBy(
        coachPropertiesCoaches.map((cp) => ({
          coachId: cp.coachId,
          label: cp.label,
          field: cp.field,
          inputValue: cp.inputValue,
          description: cp?.coach?.description,
        })),
        'coachId',
      );
      const newCoachProperties = Object.keys(groupCoachPropertiesCoaches).map((key) => {
        debugger;
        const rs = {
          coachId: key,
          coach_desc: groupCoachPropertiesCoaches[key][0].description,
        };
        groupCoachPropertiesCoaches[key]?.forEach((cp) => {
          if (['goals', 'careeer_stage', 'language', 'age_range'].includes(cp.field)) {
            rs[cp.field] = cp.inputValue?.split(',');
          } else {
            rs[cp.field] = cp.inputValue;
          }
        });
        return rs;
      });
      await dispatch(
        doCreateCoachingInquiriesUsers({
          participantProperties: {
            ...parseArrayCoachingInquiriesUsersToObject(newCoachPropertiesUsers),
            goals: selectedItems,
            ...parseArrayCoachingInquiriesUsersToObject(coachPropertiesUsersBefore),
          },
          coachProperties: newCoachProperties,
        }),
      );
    } else {
      setCoachPropertiesUsersBefore(newCoachPropertiesUsers);
      setCurrentStep(currentStep + 1);
    }
  };

  const parseArrayCoachingInquiriesUsersToObject = (array) => {
    const newObj = {};
    array.forEach((item) => {
      newObj[item.field] = item.inputValue;
    });
    return newObj;
  };

  const toggleItem = (item) => {
    if (selectedItems.length === 3 && !selectedItems.includes(item)) {
      dispatch(setError({ message: 'Please select up to 3 items.' }));
      return;
    }
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(item) ? prevSelectedItems.filter((i) => i !== item) : [...prevSelectedItems, item],
    );
  };

  const selectedMember = (member) => {
    setStateCoachingPopup({
      ...stateCoachingPopup,
      show: true,
      member,
    });
  };

  return (
    <Spin spinning={isFetching}>
      <div className="CoachingInquiryPage">
        <div className="header">
          <div className="content">
            {currentStep < 2 && currentStep > 0 && (
              <ReactSVG className="icon" src="/icons/arrow_back.svg" onClick={() => setCurrentStep(currentStep - 1)} />
            )}
            <p>{currentStep != 2 ? 'Coaching Inquiry' : 'Coach'}</p>
          </div>
          <Link to="/logout">
            Logout
            <ReactSVG className="icon" src="/icons/logout.svg" />
          </Link>
        </div>
        <div className={`body ${currentStep != 2 && currentStep != 0 ? 'body-padding' : ''}`}>
          {currentStep === 0 && (
            <div className="title">
              <h2>Here is a list of goals that you can be coached on.</h2>
              <p>Please pick your THREE most important coaching goals. Please tick maximum of 3 goals</p>
            </div>
          )}
          {currentStep === -1 && (
            <div className="form-wrapper">
              <Form
                form={formBefore}
                scrollToFirstError
                layout="vertical"
                requiredMark={false}
                colon={false}
                onFinish={(values) => handleSubmitNonGoals(values, 'Before')}
              >
                {coachProperties?.length > 0 &&
                  coachProperties
                    .filter((c) => FIELD_BEFORE_GOALS.includes(c.field))
                    .map((rg) => {
                      let content = <></>;
                      switch (rg.type) {
                        case COACH_PROPERTY_TYPE.INPUT:
                          content = <Input size="large" placeholder={rg.name} />;
                          break;
                        case COACH_PROPERTY_TYPE.SELECT:
                          content = (
                            <Select
                              size="large"
                              placeholder={rg.name}
                              options={rg.choiceValues.map((cv) => ({
                                label: cv.label,
                                value: cv.label,
                              }))}
                            />
                          );
                          break;
                        case COACH_PROPERTY_TYPE.RADIO:
                          content = (
                            <Radio.Group name="radiogroup" direction="vertical">
                              <Space direction="vertical">
                                {rg.choiceValues.map((c) => (
                                  <Radio key={c.label} value={c.label}>
                                    {c.label}
                                  </Radio>
                                ))}
                              </Space>
                            </Radio.Group>
                          );
                          break;
                        case COACH_PROPERTY_TYPE.TEXTAREA:
                          content = <Input.TextArea size="large" placeholder={rg.label} rows={4} />;
                          break;
                      }
                      return (
                        <Form.Item
                          label={rg.label}
                          name={rg.label}
                          key={`${rg.id} ${rg.priority} coach property`}
                          rules={[
                            {
                              required: currentStep === -1,
                              message: 'Required',
                            },
                          ]}
                        >
                          {content}
                        </Form.Item>
                      );
                    })}
              </Form>
            </div>
          )}
          {currentStep === 0 && (
            <div className="items-container">
              {coachingInquiries.map((coachingInquiry) => (
                <div
                  key={`${coachingInquiry.id}` + coachingInquiry.name}
                  className={`item ${selectedItems.includes(coachingInquiry.name) ? 'selected' : ''}`}
                  onClick={() => toggleItem(coachingInquiry.name)}
                >
                  {coachingInquiry.name}
                  {selectedItems.includes(coachingInquiry.name) && (
                    <ReactSVG className="checkmark" src="/icons/check.svg" />
                  )}
                </div>
              ))}
            </div>
          )}
          {currentStep === 1 && (
            <div className="form-wrapper">
              <Form
                form={formAfter}
                scrollToFirstError
                layout="vertical"
                requiredMark={false}
                colon={false}
                onFinish={handleSubmitNonGoals}
              >
                {coachProperties?.length > 0 &&
                  coachProperties
                    .filter((c) => !FIELD_BEFORE_GOALS.includes(c.field))
                    .map((rg) => {
                      let content = <></>;
                      switch (rg.type) {
                        case COACH_PROPERTY_TYPE.INPUT:
                          content = <Input size="large" placeholder={rg.name} />;
                          break;
                        case COACH_PROPERTY_TYPE.SELECT:
                          content = (
                            <Select
                              size="large"
                              placeholder={rg.name}
                              options={rg.choiceValues.map((cv) => ({
                                label: cv.label,
                                value: cv.label,
                              }))}
                            />
                          );
                          break;
                        case COACH_PROPERTY_TYPE.RADIO:
                          content = (
                            <Radio.Group name="radiogroup" direction="vertical">
                              <Space direction="vertical">
                                {rg.choiceValues.map((c) => (
                                  <Radio key={c.label} value={c.label}>
                                    {c.label}
                                  </Radio>
                                ))}
                              </Space>
                            </Radio.Group>
                          );
                          break;
                        case COACH_PROPERTY_TYPE.TEXTAREA:
                          content = <Input.TextArea size="large" placeholder={rg.label} rows={4} />;
                          break;
                      }
                      return (
                        <Form.Item
                          label={rg.label}
                          name={rg.label}
                          key={`${rg.id} ${rg.priority} coach property`}
                          rules={[
                            {
                              required: currentStep === 1,
                              message: 'Required',
                            },
                          ]}
                        >
                          {content}
                        </Form.Item>
                      );
                    })}
              </Form>
            </div>
          )}
          {currentStep === 2 && (
            <div className="coaching-members">
              <div className="card">
                <div className="card-header">
                  <div className="card-avatar">
                    <img src={coach.url} />
                  </div>
                  <div className="card-info">
                    <h3>{coach.fullName}</h3>
                    <p>{coach.role}</p>
                  </div>
                </div>
                <div className="card-content">
                  <p>{coach.description}</p>
                  <div className="tags">
                    {coach.tags.map((t) => (
                      <div className="tag" key={coach.id + t}>
                        {t}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {currentStep != 2 && (
          <div className="footer">
            <Button type="primary" htmlType="submit" block size="large" onClick={continueStep}>
              Continue
            </Button>
          </div>
        )}
        <CoachingMemberPopup show={stateCoachingPopup.show} onClose={onClosePopup} member={stateCoachingPopup.member} />
      </div>
    </Spin>
  );
};

export default CoachingInquiryPage;
