import { useCallback, useMemo } from 'react';
import { SELECT_ALL_OPTION } from '../constants';

function useSelectAllOption(options) {
  const optionsWithAllOption = useMemo(() => [SELECT_ALL_OPTION, ...options], [options]);

  /** pass this to Form.Item's getValueFromEvent prop */
  const getValueFromEvent = useCallback(
    (value, selections) => {
      if (!selections?.length) return selections;
      if (!selections?.some((s) => s.value === SELECT_ALL_OPTION.value)) {
        return selections.map((o) => o.value);
      }
      // if "Select All" option selected, set value to all options
      // also keep labelInValue in consideration
      return options.map((o) => o.value);
    },
    [options],
  );

  return [getValueFromEvent, optionsWithAllOption];
}

export default useSelectAllOption;
