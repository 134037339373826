import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllCoaches } from '../modules/coaches/stores/slice';
import { doCreateCoach, doGetCoaches, doUpdateCoach } from '../modules/coaches/stores/thunk';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../constants';
import { Button, Modal, Spin } from 'antd';
import { ReactSVG } from 'react-svg';
import CoachesTable from '../components/Tables/CoachesTable';
import './Coaches.scss';
import CoachForm from '../components/Forms/Coach';
import { convertModelToFormData } from '../utils/common';
import { UnorderedListOutlined } from '@ant-design/icons';
import CoachQuestionForm from '../components/Forms/CoachQuestion';
import { doCreateCoachPropertiesCoach } from '../modules/coachPropertyCoach/stores/thunk';
import queryString from 'query-string';

const initStateModalCoach = {
  open: false,
  type: '',
  coach: null,
  action: 'ide',
};

const CoachesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFetching = useSelector((state) => state.coach.isFetching);
  const status = useSelector((state) => state.coach.status);
  const action = useSelector((state) => state.coach.action);
  const metadata = useSelector((state) => state.coach.metadata);
  const coaches = useSelector((state) => selectAllCoaches(state));

  const [searchParams] = useSearchParams();

  const query = Object.fromEntries(searchParams);
  const currentPage = query.current || 1;
  const itemsPerPage = query.pageSize || PAGE_SIZE;
  const pagination = {
    current: currentPage || 1,
    pageSize: itemsPerPage,
    locale: { items_per_page: 'Coaches/Page' },
    total: metadata.totalItems || coaches.length,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
    showTotal: (total) => `${total} Coaches`,
  };

  const [stateModalCoach, setStateModalCoach] = useState(initStateModalCoach);

  useEffect(() => {
    dispatch(doGetCoaches({ page: currentPage, take: itemsPerPage, ...query }));
  }, []);

  useEffect(() => {
    if (['create', 'update'].includes(action) && status === 'succeeded') {
      dispatch(doGetCoaches({ page: currentPage, take: itemsPerPage, ...query }));
      handleCancelModal();
    }
  }, [isFetching]);

  const handleCancelModal = () => {
    setStateModalCoach(initStateModalCoach);
  };

  const onClickEdit = (record) => {
    setStateModalCoach({
      ...stateModalCoach,
      open: true,
      coach: record,
      action: 'edit',
      type: 'detail',
    });
  };

  const onClickNew = () => {
    setStateModalCoach({
      ...stateModalCoach,
      open: true,
      coach: null,
      action: 'new',
      type: 'detail',
    });
  };

  const onClickQuestion = (record) => {
    setStateModalCoach({
      ...stateModalCoach,
      open: true,
      coach: record,
      action: '',
      type: 'question',
    });
  };

  const handleSubmitModal = (data) => {
    const avatar = data.avatar?.length > 0 ? data.avatar[0].originFileObj : undefined;
    delete data.avatar;
    const formData = convertModelToFormData(data);
    if (avatar) formData.append('avatar', avatar);
    if (stateModalCoach.action === 'new') {
      dispatch(doCreateCoach(formData));
    } else {
      formData.append('id', stateModalCoach.coach.id);
      dispatch(doUpdateCoach(formData));
    }
  };

  const handleSubmitQuestion = (data) => {
    console.log({ data });
    dispatch(doCreateCoachPropertiesCoach(data));
  };

  const pushTo = (pushProps) => {
    navigate({
      pathname: pushProps.path || location.pathname,
      search: queryString.stringify(pushProps.query || {}, {
        skipNull: true,
      }),
      state: pushProps.state,
    });
  };

  const handleFilter = (params, updatePath) => {
    delete params.pageSizeOptions;
    if (updatePath) {
      pushTo({ query: { ...query, current: params.current, pageSize: params.pageSize } });
    }
    const newCurrentPage = Number(params?.current || 1);
    const newItemsPerPage = Number(params?.pageSize) || PAGE_SIZE;
    dispatch(
      doGetCoaches({
        page: newCurrentPage,
        take: newItemsPerPage,
        ...params,
      }),
    );
  };

  const handleTableChange = (params) => {
    const newParams = { ...query, ...params };
    handleFilter(newParams, true);
  };

  const withAction = (record) => {
    return (
      <div className="actions">
        <Button type="link" className="edit" onClick={() => onClickEdit(record)}>
          <ReactSVG className="icon" src="/icons/edit.svg" />
        </Button>
        <Button type="link" className="more" onClick={() => onClickQuestion(record)}>
          <UnorderedListOutlined />
        </Button>
        <Button type="link" className="edit" onClick={() => navigate('/admin/users?coachId=' + record.id)}>
          <ReactSVG className="icon" src="/icons/visibility.svg" />
        </Button>
        <Button type="link" className="more">
          <ReactSVG className="icon" src="/icons/more.svg" />
        </Button>
      </div>
    );
  };

  return (
    <Spin spinning={isFetching}>
      <div className="Content CoachesPage">
        <div className="ContentHeader">
          <div className="HeaderTitle">Coaches</div>
          <div className="HeaderToolBar">
            <div className="ToolBarButtons">
              <Button className="CreateButton ButtonPrimary" type="primary" size="small" onClick={onClickNew}>
                Create New Coach
                <ReactSVG className="icon" src="/icons/add.svg" />
              </Button>
            </div>
          </div>
        </div>
        <div className="ContentMain">
          <CoachesTable
            dataSource={coaches}
            pagination={pagination}
            withAction={withAction}
            rowKey="id"
            isFetching={false}
            onChange={handleTableChange}
          />
        </div>
        <Modal open={stateModalCoach.open} onCancel={handleCancelModal} footer={[]} width={792} forceRender>
          {stateModalCoach.type === 'detail' ? (
            <CoachForm
              handleSubmit={handleSubmitModal}
              handleCancel={handleCancelModal}
              coach={stateModalCoach.coach}
              action={stateModalCoach.action}
              isFetching={isFetching}
            />
          ) : (
            <CoachQuestionForm
              handleSubmit={handleSubmitQuestion}
              handleCancel={handleCancelModal}
              action={stateModalCoach.action}
              coach={stateModalCoach.coach}
              isFetching={isFetching}
            />
          )}
        </Modal>
      </div>
    </Spin>
  );
};
export default CoachesPage;
