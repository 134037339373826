import React from 'react';
import { Layout, Dropdown, Button } from 'antd';
import { Link } from 'react-router-dom';
import { CaretDownOutlined } from '@ant-design/icons';

import MainMenu from '../Menu/Main';
import { hexToHexA } from '../utils/color';
import { useSelector } from 'react-redux';

const { Header } = Layout;

const MainHeader = () => {
  const user = useSelector((state) => state.auth.user);
  const backgroundOpacity = hexToHexA(user?.primaryColor || '#93BFFF', 0.24);
  const avatarBackground = backgroundOpacity?.replace('#', '');
  const avatarColor = user?.primaryColor?.replace('#', '');

  const default_logo = `/logo-header.svg`;

  const dropdowns = [
    {
      label: (
        <Link to="/logout" className="test">
          <Button type="text" size="small">
            Log Out
          </Button>
        </Link>
      ),
      key: '/logout',
    },
  ];

  return (
    <Header className="MainHeader" style={{ position: 'fixed', zIndex: 9999, width: '100%' }}>
      <div className="logo">
        <Link to="/">
          <img src={default_logo} alt="logo" />
        </Link>
      </div>
      {user && (
        <>
          <MainMenu user={user} />
          <Dropdown menu={{ items: dropdowns }}>
            <div className="account-info">
              <div className="avatar">
                <img
                  src={`https://ui-avatars.com/api/?length=1&name=${
                    user?.givenName || user?.email
                  }&size=32&font-size=0.4&bold=true&background=${
                    avatarBackground || '9863f3'
                  }&color=${avatarColor}&rounded=true`}
                  alt=""
                />
              </div>

              <div className="info">
                <div className="fullname">{user?.givenName}</div>

                <div className="email">{user?.email}</div>
              </div>
              <CaretDownOutlined />
            </div>
          </Dropdown>
        </>
      )}
    </Header>
  );
};

export default MainHeader;
