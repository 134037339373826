import React from 'react';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import './SignIn.scss';

const SignInForm = ({ onLogin }) => {
  const [form] = Form.useForm();

  const handleSubmit = (params) => {
    onLogin && onLogin(params);
  };

  return (
    <div className={`SignInForm`}>
      <Form form={form} scrollToFirstError layout="vertical" hideRequiredMark colon={false} onFinish={handleSubmit}>
        <div className="FormContent">
          <h1>Login</h1>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: 'email', message: 'Invalid Email' },
              { required: true, message: 'Required' },
            ]}
          >
            <Input size="large" placeholder="Email" />
          </Form.Item>
          <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Required' }]}>
            <Input.Password size="large" placeholder="Password" />
          </Form.Item>
        </div>
        <div className="FormFooter">
          <Button type="primary" htmlType="submit" block size="large">
            Login
          </Button>
          <div className="ForgotPassword">
            <Link to="#">Forgot your password?</Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SignInForm;
