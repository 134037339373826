import request from '../../utils/request';

export function getCoachPropertiesCoach(params) {
  return request(`/coaching-properties-coaches/${params.id}`, {
    method: 'GET',
  });
}

export function createCoachPropertiesCoach(data) {
  return request('/coaching-properties-coaches', {
    method: 'POST',
    data,
  });
}

export function getCoachPropertiesCoachesAll() {
  return request('/coaching-properties-coaches/all', {
    method: 'GET',
  });
}
