import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './FormConfiguration.scss';
import BreadCrumb from '../modules/breadcrumbs';
import { COACH_PROPERTY_TYPE } from '../constants';
import { ReactSVG } from 'react-svg';
import { PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import sortBy from 'lodash/sortBy';
import { doCreateCoachProperties, doGetCoachProperties } from '../modules/coachProperty/stores/thunk';

const initValueProperties = {
  label: '',
  priority: 9999,
  type: COACH_PROPERTY_TYPE.INPUT,
};

const FormConfiguration = () => {
  const [form] = Form.useForm();
  const breadCrumbs = [{ text: 'Form configuration' }];
  const dispatch = useDispatch();

  const handleSubmit = (params) => {
    const coachProperties = params.coachProperties.map((coachProperty) => {
      return {
        label: coachProperty.label,
        labelEmail: coachProperty.labelEmail,
        field: coachProperty.field,
        priority: coachProperty.priority,
        type: coachProperty.type,
        choiceValues: coachProperty.choiceValues?.map((choiceValue) => {
          return {
            label: choiceValue.label,
            priority: choiceValue.priority,
          };
        }),
      };
    });
    dispatch(doCreateCoachProperties({ coachProperties }));
  };

  const uniqueOptionValidator = () => {
    const fields = form.getFieldValue('coachProperties');
    if (!fields) return Promise.resolve();

    for (const field of fields) {
      const choiceValues = field.choiceValues;
      if (!choiceValues) continue;

      const labels = choiceValues.map((choice) => choice.label);
      const duplicates = labels.filter((label, index) => labels.indexOf(label) !== index);
      if (duplicates.length) {
        return Promise.reject(new Error('Option must be unique'));
      }
    }
    return Promise.resolve();
  };

  const uniqueQuestionValidator = () => {
    const fields = form.getFieldValue('coachProperties');
    if (!fields) return Promise.resolve();

    const labels = fields.map((choice) => choice.label);
    const duplicates = labels.filter((label, index) => labels.indexOf(label) !== index);
    if (duplicates.length) {
      return Promise.reject(new Error('Question must be unique'));
    }
    return Promise.resolve();
  };

  const { items: coachProperties, isFetching } = useSelector((state) => state.coachProperty);

  useEffect(() => {
    dispatch(doGetCoachProperties());
  }, []);

  useEffect(() => {
    form.setFieldsValue({ coachProperties });
  }, [coachProperties]);

  const onBlurForm = () => {
    const coachProperties = form.getFieldValue('coachProperties');

    const isNullPriority = coachProperties?.find(
      (value) => !value?.priority || value.choiceValues?.find((choiceValue) => !choiceValue?.priority),
    );
    if (coachProperties?.length > 0 && !isNullPriority) {
      let index = 1;
      const newCoachProperties = sortBy(coachProperties, (value) => Number(value?.priority || 9999)).map((value) => {
        const newPriority = index;
        index = index + 2;
        let indexChoice = 1;
        const newChoiceValues =
          value.choiceValues?.length > 0
            ? sortBy(value.choiceValues, (valueChoice) => Number(valueChoice?.priority || 9999)).map((choiceValue) => {
                const newPriorityChoice = indexChoice;
                indexChoice = indexChoice + 2;
                return { ...choiceValue, priority: newPriorityChoice };
              })
            : undefined;
        return {
          ...value,
          priority: newPriority,
          choiceValues: newChoiceValues,
        };
      });

      form.setFieldsValue({ coachProperties: newCoachProperties });
    }
  };

  return (
    <Spin spinning={isFetching}>
      <div className="FormConfiguration">
        <BreadCrumb breadCrumbs={breadCrumbs} />
        <div className="wrapper">
          <div className="header">
            <h4 className="title">Form Configuration</h4>
          </div>
          <div className="body">
            <div className="FormContent">
              <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                colon={false}
                onFinish={handleSubmit}
                onBlur={onBlurForm}
              >
                <Form.List
                  name="coachProperties"
                  rules={[
                    {
                      validator: async (_, fields) => {
                        if (!fields || fields.length < 1) {
                          return Promise.reject(new Error('At least one question is required'));
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map(({ key, name, ...restField }) => {
                        return (
                          <div key={key} className="form-item">
                            <Row gutter={[24, 24]}>
                              <Col span={24}>
                                <div className="action">
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'label']}
                                    label="Question"
                                    rules={[
                                      { required: true, message: 'Required' },
                                      { validator: uniqueQuestionValidator },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                  <ReactSVG
                                    className="icon pointer"
                                    src="/icons/delete_forever.svg"
                                    onClick={() => remove(name)}
                                  />
                                </div>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'labelEmail']}
                                  label="Label Email"
                                  rules={[{ required: true, message: 'Required' }]}
                                >
                                  <Input size="large" placeholder="Label Email" />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'field']}
                                  label="Field"
                                  rules={[{ validator: uniqueQuestionValidator }]}
                                >
                                  <Input size="large" placeholder="Field" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'priority']}
                                  label="Priority"
                                  rules={[{ required: true, message: 'Required' }]}
                                >
                                  <Input size="large" type="number" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'type']}
                                  label="Answer Type"
                                  rules={[{ required: true, message: 'Required' }]}
                                >
                                  <Select
                                    size="large"
                                    options={Object.keys(COACH_PROPERTY_TYPE).map((key) => ({
                                      value: key,
                                      label: COACH_PROPERTY_TYPE[key],
                                    }))}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.coachProperties?.[name]?.type !==
                                    currentValues.coachProperties?.[name]?.type
                                  }
                                  noStyle
                                >
                                  {({ getFieldValue }) => {
                                    const type = getFieldValue(['coachProperties', name, 'type']);
                                    return !(
                                      type === COACH_PROPERTY_TYPE.RADIO || type === COACH_PROPERTY_TYPE.SELECT
                                    ) ? null : (
                                      <Form.List
                                        name={[name, 'choiceValues']}
                                        rules={[
                                          {
                                            validator: async (_, fields) => {
                                              if (!fields || fields.length < 1) {
                                                return Promise.reject(new Error('At least one option is required'));
                                              }
                                            },
                                          },
                                        ]}
                                      >
                                        {(subFields, subOpt) => (
                                          <div>
                                            {subFields.map((subField) => (
                                              <Row gutter={[24, 24]} key={subField.key}>
                                                <Col span={18}>
                                                  <Form.Item
                                                    label="Option"
                                                    name={[subField.name, 'label']}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: 'Required',
                                                      },
                                                      {
                                                        validator: uniqueOptionValidator,
                                                      },
                                                    ]}
                                                  >
                                                    <Input placeholder="Option" size="large" />
                                                  </Form.Item>
                                                </Col>

                                                <Col span={6}>
                                                  <div className="action">
                                                    <Form.Item
                                                      label="Priority"
                                                      name={[subField.name, 'priority']}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'Required',
                                                        },
                                                      ]}
                                                    >
                                                      <Input placeholder="Priority" size="large" type="number" />
                                                    </Form.Item>
                                                    <ReactSVG
                                                      className="icon pointer"
                                                      src="/icons/remove_circle_outline.svg"
                                                      onClick={() => subOpt.remove(subField.name)}
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                            ))}
                                            <Form.Item
                                              shouldUpdate={(prevValues, currentValues) =>
                                                prevValues.coachProperties?.[name]?.choiceValues !==
                                                currentValues.coachProperties?.[name]?.choiceValues
                                              }
                                              noStyle
                                            >
                                              {({ getFieldError }) => {
                                                const errors = getFieldError(['coachProperties', name, 'choiceValues']);
                                                return errors.length ? (
                                                  <div
                                                    style={{
                                                      color: 'red',
                                                      marginTop: 10,
                                                    }}
                                                  >
                                                    {errors[0]}
                                                  </div>
                                                ) : null;
                                              }}
                                            </Form.Item>
                                            <Button type="text" className="btn-add" onClick={() => subOpt.add()}>
                                              Add Option
                                              <PlusOutlined />
                                            </Button>
                                          </div>
                                        )}
                                      </Form.List>
                                    );
                                  }}
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                      <Button type="text" size="large" className="btn-add" onClick={() => add(initValueProperties)}>
                        Add Question
                        <PlusCircleOutlined />
                      </Button>
                      <Form.Item
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.coachProperties !== currentValues.coachProperties
                        }
                        noStyle
                      >
                        {({ getFieldError }) => {
                          const errors = getFieldError('coachProperties');
                          return errors.length ? (
                            <div
                              style={{
                                color: 'red',
                                marginTop: 10,
                              }}
                            >
                              {errors[0]}
                            </div>
                          ) : null;
                        }}
                      </Form.Item>
                    </div>
                  )}
                </Form.List>
              </Form>
            </div>
          </div>
          <div className="footer">
            <Button type="primary" htmlType="submit" size="large" onClick={() => form.submit()}>
              Update
              <ReactSVG className="icon" src="/icons/check_white.svg" />
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default FormConfiguration;
