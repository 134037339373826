import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Spin } from 'antd';

import { doGetProfile } from '../stores/thunk';
import MainLayout from '../../../Layouts/Main';

const Container = ({ allowedRoles, children }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const error = useSelector((state) => state.common.error);

  useEffect(() => {
    if (auth && auth.authenticated && !auth.user) {
      dispatch(doGetProfile());
    }
  }, []);

  if (auth && auth.authenticated && error.statusCode === 401) {
    return <Navigate to="/logout" />;
  }

  const role = auth.user?.role?.toLowerCase();

  if (auth && auth.authenticated && !auth.user) return <Spin style={{ paddingTop: 100, width: '100%' }} />;
  if (auth && auth.authenticated) {
    if (allowedRoles.includes(role)) {
      return children && <MainLayout isHeaderFooter={role === 'admin'}>{children}</MainLayout>;
    } else {
      return <Navigate to={role === 'user' ? '/coaching-inquiry' : '/admin/coaches'} />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default Container;
