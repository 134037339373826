import { createSlice } from '@reduxjs/toolkit';

import { doCreateCoachingInquiriesUsers, doGetCoachingInquiriesUsers } from './thunk';

const authSlice = createSlice({
  name: 'coachingInquiryUser',
  selectedCoach: null,
  initialState: {
    isFetching: false,
    items: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetCoachingInquiriesUsers.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetCoachingInquiriesUsers.fulfilled, (state, action) => {
      state.isFetching = false;
      state.items = action.payload;
    });
    builder.addCase(doGetCoachingInquiriesUsers.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doCreateCoachingInquiriesUsers.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doCreateCoachingInquiriesUsers.fulfilled, (state, action) => {
      state.selectedCoach = action.payload;
      state.isFetching = false;
    });
    builder.addCase(doCreateCoachingInquiriesUsers.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

const { actions, reducer: authReducer } = authSlice;
export const { doLogout } = actions;
export default authReducer;
