import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { doCreateCoach, doGetCoach, doGetCoaches, doGetCoachesByUser, doUpdateCoach } from './thunk';

const isActionWithPending = (action) => {
  return action.type && action.type.startsWith('Coaches/') && action.type.endsWith('/pending');
};

const isActionWithFulfilled = (action) => {
  return action.type && action.type.startsWith('Coaches/') && action.type.endsWith('/fulfilled');
};

const isActionWithFailed = (action) => {
  return action.type && action.type.startsWith('Coaches/') && action.type.endsWith('/rejected');
};

const coachesAdapter = createEntityAdapter({
  selectId: (item) => item.id,
});

const coachSlice = createSlice({
  name: 'coach',
  initialState: {
    action: null,
    isFetching: false,
    currentCoach: null,
    status: 'idle',
    metadata: {},
    entities: [],
    ids: [],
    item: null,
  },
  reducers: {
    resetCurrentCoach: (state) => {
      state.item = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGetCoaches.pending, (state) => {
      state.action = 'getall';
      state.status = 'loading';
    });
    builder.addCase(doGetCoaches.fulfilled, (state, action) => {
      const { coaches, pagination } = action.payload;
      state.status = 'succeeded';
      state.metadata = pagination;
      coachesAdapter.setAll(state, coaches);
    });
    builder.addCase(doGetCoaches.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetCoachesByUser.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetCoachesByUser.fulfilled, (state, action) => {
      state.status = 'succeeded';
      if (action.payload) {
        state.currentCoach = action.payload;
      }
    });
    builder.addCase(doGetCoachesByUser.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doCreateCoach.pending, (state) => {
      state.action = 'create';
      state.status = 'loading';
    });
    builder.addCase(doCreateCoach.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doCreateCoach.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doUpdateCoach.pending, (state) => {
      state.action = 'update';
      state.status = 'loading';
    });
    builder.addCase(doUpdateCoach.fulfilled, (state) => {
      state.status = 'succeeded';
    });
    builder.addCase(doUpdateCoach.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addCase(doGetCoach.pending, (state) => {
      state.action = 'getOne';
      state.status = 'loading';
    });
    builder.addCase(doGetCoach.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.item = action.payload;
    });
    builder.addCase(doGetCoach.rejected, (state) => {
      state.status = 'failed';
    });
    builder.addMatcher(isActionWithPending, (state) => {
      state.isFetching = true;
      state.message = null;
    });
    builder.addMatcher(isActionWithFulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addMatcher(isActionWithFailed, (state) => {
      state.isFetching = false;
      state.message = null;
    });
  },
});

export const {
  selectAll: selectAllCoaches,
  selectById: selectCoachById,
  selectIds: selectCoachIds,
} = coachesAdapter.getSelectors((state) => state.coach);
const { actions, reducer: CoachReducer } = coachSlice;
export const { resetCurrentCoach } = actions;
export default CoachReducer;
