import React, { useEffect } from 'react';
import { notification, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../redux/slice';

const { Text } = Typography;

const Description = ({ errors }) => (
  <div className="notification-description">
    {errors?.map((msg, index) => {
      return (
        <p key={index}>
          <Text type="danger">{msg}</Text>
        </p>
      );
    })}
  </div>
);

const AppError = () => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const error = useSelector((state) => state.common.error);

  useEffect(() => {
    const { message, errors } = error;
    if (!message) return;
    if (Array.isArray(message)) {
      message.forEach((msg) => {
        api.error({
          message: msg,
          description: errors && <Description errors={errors} />,
          placement: 'bottomRight',
          onClose: () => dispatch(setError({})),
        });
      });
    } else {
      api.error({
        message: message,
        description: errors && <Description errors={errors} />,
        placement: 'bottomRight',
        onClose: () => dispatch(setError({})),
      });
    }
  }, [error, setError]);

  return <>{contextHolder}</>;
};

export default AppError;
