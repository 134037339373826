import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import router from './App/router';
import './index.scss';
import { Provider } from 'react-redux';
import store from './redux';
import AppError from './App/AppError';
import AppMessage from './App/AppMessage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            controlHeightLG: 45,
            colorPrimary: '#69140E',
            fontFamily: '"Avenir", sans-serif',
          },
        }}
      >
        <AppError />
        <AppMessage />
        <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
