import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../constants';
import { Button, Spin } from 'antd';
import { ReactSVG } from 'react-svg';
import './Users.scss';
import { selectAllUsers } from '../modules/users/stores/slice';
import { doGetUsers } from '../modules/users/stores/thunk';
import UsersTable from '../components/Tables/UsersTable';
import { doGetCoach } from '../modules/coaches/stores/thunk';
import { resetCurrentCoach } from '../modules/coaches/stores/slice';
import queryString from 'query-string';

const UsersPage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isFetching = useSelector((state) => state.user.isFetching);
  const metadata = useSelector((state) => state.user.metadata);
  const users = useSelector((state) => selectAllUsers(state));
  const [searchParams] = useSearchParams();

  const query = Object.fromEntries(searchParams);
  const currentPage = query.current || 1;
  const itemsPerPage = query.pageSize || PAGE_SIZE;
  const pagination = {
    current: currentPage || 1,
    pageSize: itemsPerPage,
    locale: { items_per_page: 'Users/Page' },
    total: metadata.totalItems || users.length,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
    showTotal: (total) => `${total} Users`,
  };

  const coach = useSelector((state) => state.coach.item);

  useEffect(() => {
    if (query.coachId) {
      dispatch(doGetCoach(query.coachId));
    } else {
      if (coach) dispatch(resetCurrentCoach());
    }
    dispatch(doGetUsers({ page: currentPage, take: itemsPerPage, ...query }));
  }, []);

  const pushTo = (pushProps) => {
    navigate({
      pathname: pushProps.path || location.pathname,
      search: queryString.stringify(pushProps.query || {}, {
        skipNull: true,
      }),
      state: pushProps.state,
    });
  };

  const handleFilter = (params, updatePath) => {
    delete params.pageSizeOptions;
    if (updatePath) {
      pushTo({ query: { ...query, current: params.current, pageSize: params.pageSize } });
    }
    const newCurrentPage = Number(params?.current || 1);
    const newItemsPerPage = Number(params?.pageSize) || PAGE_SIZE;
    dispatch(
      doGetUsers({
        page: newCurrentPage,
        take: newItemsPerPage,
        ...params,
      }),
    );
  };

  const handleTableChange = (params) => {
    const newParams = { ...query, ...params };
    handleFilter(newParams, true);
  };

  const withAction = (record) => {
    return (
      <div className="actions">
        <Button type="link" className="edit" onClick={() => navigate('/admin/users/' + record.id)}>
          <ReactSVG className="icon" src="/icons/visibility.svg" />
        </Button>
        <Button type="link" className="more">
          <ReactSVG className="icon" src="/icons/more.svg" />
        </Button>
      </div>
    );
  };

  const title = coach ? coach.fullName + "'s participants" : 'Participants';

  return (
    <Spin spinning={isFetching}>
      <div className="Content UsersPage">
        <div className="ContentHeader">
          <div className="HeaderTitle">{title}</div>
          <div className="HeaderToolBar"></div>
        </div>
        <div className="ContentMain">
          <UsersTable
            dataSource={users}
            pagination={pagination}
            withAction={withAction}
            rowKey="id"
            isFetching={isFetching}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </Spin>
  );
};
export default UsersPage;
