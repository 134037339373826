import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doGetCoachPropertiesCoach = createAsyncThunk(
  'CoachPropertiesCoach/doGetCoachPropertiesCoach',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.getCoachPropertiesCoach(params);
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  },
);

export const doGetCoachPropertiesCoachAll = createAsyncThunk(
  'CoachPropertiesCoach/doGetCoachPropertiesCoachAll',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.getCoachPropertiesCoachesAll(params);
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  },
);

export const doCreateCoachPropertiesCoach = createAsyncThunk(
  'CoachPropertiesCoach/doCreateCoachPropertiesCoach',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.createCoachPropertiesCoach(params);
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  },
);
