import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import './Main.scss';

const MainMenu = () => {
  const location = useLocation();
  const pathname = location.pathname;

  let selectedKey = pathname;

  if (selectedKey.includes('coaches')) {
    selectedKey = '/coaches';
  } else if (selectedKey.includes('users')) {
    selectedKey = '/users';
  } else if (selectedKey.includes('form-configuration')) {
    selectedKey = '/form-configuration';
  }

  const items = [
    {
      label: (
        <div className="MenuItemContent">
          <Link to="/admin/coaches">Coaches</Link>
        </div>
      ),
      key: '/coaches',
    },
    {
      label: (
        <div className="MenuItemContent">
          <Link to="/admin/users">Users</Link>
        </div>
      ),
      key: '/users',
    },
    {
      label: (
        <div className="MenuItemContent">
          <Link to="/admin/form-configuration">Form Configuration</Link>
        </div>
      ),
      key: '/form-configuration',
    },
  ];

  return <Menu className={`MainMenu`} selectedKeys={[selectedKey]} mode="horizontal" items={items} />;
};

export default MainMenu;
