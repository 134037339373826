import { createSlice } from '@reduxjs/toolkit';

import { doCreateCoachProperties, doGetCoachProperties } from './thunk';

const coachPropertySlice = createSlice({
  name: 'coachProperty',
  initialState: {
    isFetching: false,
    items: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetCoachProperties.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doGetCoachProperties.fulfilled, (state, action) => {
      state.isFetching = false;
      state.items = action.payload;
    });
    builder.addCase(doGetCoachProperties.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doCreateCoachProperties.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(doCreateCoachProperties.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(doCreateCoachProperties.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

const { reducer: coachPropertyReducer } = coachPropertySlice;

export default coachPropertyReducer;
