import request from '../../utils/request';

export function getCoachingInquiriesUsers() {
  return request('/coaching-inquiries-users', {
    method: 'Get',
  });
}

export function createCoachingInquiriesUsers(data) {
  return request('/coaching-inquiries-users', {
    method: 'POST',
    data,
  });
}
