import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doGetCoaches = createAsyncThunk('Coaches/doGetCoaches', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getCoaches(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doGetCoach = createAsyncThunk('Coaches/doGetCoach', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getCoach(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doGetCoachesByUser = createAsyncThunk('Coaches/doGetCoachesByUser', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.getCoachesByUsers();
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doCreateCoach = createAsyncThunk('Coaches/doCreateCoach', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.createCoach(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});

export const doUpdateCoach = createAsyncThunk('Coaches/doUpdateCoach', async (params, thunkAPI) => {
  try {
    thunkAPI.dispatch(setGlobalLoading(true));
    const { response, error } = await api.updateCoach(params);
    thunkAPI.dispatch(setGlobalLoading(false));
    return error ? thunkAPI.rejectWithValue(error) : response;
  } catch (e) {
    thunkAPI.rejectWithValue(e);
    throw e;
  }
});
