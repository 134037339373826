import { Table } from 'antd';
import React from 'react';
import moment from 'moment';
import { DATE_TIME_FORMAT } from '../../constants';

export default function UsersTable({ withAction, dataSource, selectedRowKeys, onSelectChange, ...props }) {
  const columns = [
    {
      title: 'Given Name',
      dataIndex: 'givenName',
      key: 'givenName',
      fixed: 'left',
      width: 200,
    },
    {
      title: 'Family Name',
      dataIndex: 'familyName',
      key: 'familyName',
      fixed: 'left',
      width: 200,
    },
    {
      title: 'Access Code',
      dataIndex: 'accessCode',
      key: 'accessCode',
      width: 200,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 200,
      render: (value) => {
        return value ? moment(value).format(DATE_TIME_FORMAT) : '';
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (value) => {
        return value ? moment(value).format(DATE_TIME_FORMAT) : '';
      },
    },
  ];

  if (withAction) {
    columns.push({
      key: 'action',
      width: 120,
      fixed: 'right',
      render(record) {
        return withAction(record);
      },
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="table-job-orders table-common">
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={dataSource}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-dark' : 'table-row-light')}
        {...props}
      />
    </div>
  );
}
