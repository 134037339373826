import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalLoading } from '../../../redux/slice';
import * as api from '../api';

export const doGetCoachingInquiriesUsers = createAsyncThunk(
  'CoachingInquiriesUsers/doGetCoachingInquiriesUsers',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.getCoachingInquiriesUsers();
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  },
);

export const doCreateCoachingInquiriesUsers = createAsyncThunk(
  'CoachingInquiriesUsers/doCreateCoachingInquiriesUsers',
  async (params, thunkAPI) => {
    try {
      thunkAPI.dispatch(setGlobalLoading(true));
      const { response, error } = await api.createCoachingInquiriesUsers(params);
      thunkAPI.dispatch(setGlobalLoading(false));
      return error ? thunkAPI.rejectWithValue(error) : response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
      throw e;
    }
  },
);
